<template>
  <div
    class="tab-pane fade mt-3"
    :class="{ 'show active': !$route.hash || $route.hash == '#review' }"
  >
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="row">
          <div
            v-for="i in 6"
            class="col-12 col-lg-6 mb-3"
            :key="`unreviewed-product-skeleton-${i}`"
          >
            <div class="d-flex card-product-review">
              <b-skeleton-img class="rounded review-product-img"></b-skeleton-img>
              <div class="p-3 flex-grow-1 review-product-desc">
                <div class="d-flex justify-content-between">
                  <b-skeleton width="50px" class="mb-0"></b-skeleton>
                  <b-skeleton width="60px" class="ml-2 mb-0"></b-skeleton>
                </div>
                <b-skeleton width="80px" class="mt-2"></b-skeleton>
                <div class="d-flex mt-2">
                  <b-skeleton width="50px" class="mb-0"></b-skeleton>
                  <b-skeleton width="50px" class="ml-2 mb-0"></b-skeleton>
                  <b-skeleton width="50px" class="ml-2 mb-0"></b-skeleton>
                </div>
                <b-skeleton width="150px" height="24px" class="mt-2"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="text-center mt-5" v-if="unreviewedProducts.length == 0">
        <img src="@/assets/img/empty-review.png" alt="" width="300" height="300" />
        <h4 class="roboto">Data Review no available</h4>
      </div>
      <template v-else>
        <div class="row">
          <div class="col-12 col-lg-6 mb-3" v-for="(review, index) in unreviewedProducts" :key="index" @click="review.rating = 5; selectedReview = review" v-b-modal.modal-review-product>
            <div class="d-flex card-product-review cursor-pointer">
              <img class="rounded review-product-img" :src="review.image_url" alt="" @error="replaceByDefaultImage" />
              <div class="p-3 flex-grow-1 review-product-desc">
                <div class="d-flex justify-content-between">
                  <div class="review-product-brand line-clamp-1">{{ review.brand_name }}</div>
                  <div class="review-date text-nowrap ml-2">
                    {{ $helpers.format.epochMsToLocalTime(review.order_date_epoch, "D MMMM YYYY", "id") }}
                  </div>
                </div>
                <div class="review-product-name bold line-clamp-1">
                  {{ review.product_name }}
                </div>
                <div class="review-product-var text-grey">
                  <span>Jumlah: {{ review.quantity }}</span>
                  <span v-if="review.color"> | Warna: {{ review.color }}</span>
                  <span v-if="review.size"> | Ukuran: {{ review.size }}</span>
                </div>
                <div class="review-product-rating">
                  <fawesome-pro
                    v-for="i in 5"
                    :key="i"
                    :variant="review.rating - i > -1 ? 'fas' : 'far'"
                    icon="star"
                    class="text-warning pr-1"
                    @click.native.stop="review.rating = i; selectedReview = review"
                    v-b-modal.modal-review-product
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-pagination
          v-if="unreviewedProductRows > perPage"
          v-model="currentPage"
          :total-rows="unreviewedProductRows"
          :per-page="perPage"
          @change="pageChange"
          align="right"
        />
      </template>
    </b-skeleton-wrapper>
    <ModalReviewPerProduct @refreshList="fetchData" :review="selectedReview" @resetRating="resetRating()" />
  </div>
</template>

<script>
const ModalReviewPerProduct = () => import("@/components/modals/ModalReviewPerProduct");

export default {
  name: "ListUnreviewedProduct",
  components: {
    ModalReviewPerProduct
  },
  data() {
    return {
      isLoading: false,
      unreviewedProducts: [],
      selectedReview: null,

      // For pagination
      unreviewedProductRows: 0,
      currentPage: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    resetRating() {
      this.unreviewedProducts.find(el => el.id == this.selectedReview.id).rating = 0;
    },
    async fetchData() {
      try {
        this.isLoading = true;
        const res = await this.$api.product.getUnreviewedProductProfileList(
          this.currentPage - 1,
          this.perPage
        );
        if (res.status === 200) {
          this.unreviewedProducts = res.data.data.map(el => ({...el, rating: 0}));
          this.unreviewedProductRows = res.data.meta.total;
          this.$emit("scrollToTop");
        } else this.$helpers.toaster.make(res.data.message, "danger");
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },
    pageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    replaceByDefaultImage(event) {
      event.target.src = require("@/assets/img/default-image.png")
      event.target.classList.add("p-4", "default-image")
    },
  },
  watch: {
    "$route.hash"(newVal, oldVal) {
      if (!oldVal) return
      if (newVal == "#review") this.fetchData();
    }
  }
};
</script>

<style scoped>
.review-product-name{
  font-size: 1rem;
}
.review-product-img {
  /* width: 165px;
  height: 165px; */
  width: 140px;
  height: 140px;
}
.review-product-rating {
  font-size: 24px;
}
.review-product-brand, .review-date, .review-product-var {
  font-size: 14px;
}
@media only screen and (max-width: 992px) {
  .review-product-img {
    /* width: 150px;
    height: 150px; */
    width: 125px;
    height: 125px;
  }
  .review-product-desc {
    font-size: 14px;
  }
  .review-date, .review-product-var {
    font-size: 12px;
  }
  .review-product-rating {
    font-size: 22px;
  }
}
</style>